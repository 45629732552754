import React from "react";
import "../assets/styles/InquiryForm.css"; // Ensure this CSS file exists and is customized

function InquiryForm() {
  return (
    <div className="inquiry-form">
      <div className="container-row">
        <div className="container white-bg">
          <h2>Our Mission</h2>
          <p>
            Our mission is to provide high-quality education and support to
            students worldwide. We aim to bridge the gap between dreams and
            reality, helping students achieve their academic and career goals
            with personalized guidance and expert advice.
          </p>
        </div>
        <div className="container black-bg">
          <h2>We Fulfill Your Global Academic Aspirations</h2>
          <p>
            We are committed to helping you navigate the complex world of global
            education. Our experienced counselors will guide you through every
            step of the process, from choosing the right program to securing
            your admission and preparing for your academic journey abroad.
          </p>
        </div>
      </div>
      <div className="container-row">
        <div className="container black-bg">
          <h2>Value Added Services</h2>
          <p>
            Beyond academic counseling, we offer a range of value-added services
            including career advice, application assistance, and pre-departure
            briefings to ensure that you are well-prepared for your educational
            experience abroad.
          </p>
        </div>
        <div className="container white-bg">
          <h2>Visa Assistance</h2>
          <p>
            Our visa assistance services are designed to simplify the process of
            obtaining your student visa. We provide expert guidance on visa
            requirements, documentation, and application procedures to help
            ensure a smooth and successful visa application experience.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InquiryForm;
