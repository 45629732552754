import React from "react";
import "../assets/styles/CompanyInfo.css";
import "../assets/styles/Service.css";
import offices from "../assets/images/Office.jpeg";

function CompanyInfo() {
  return (
    <div>
      <div
        className="intross"
        style={{
          backgroundImage: `url(${offices})`,
          width: "100%",
        }}
      >
        <div className="intross-text">
          <h2>WELCOME TO</h2>
          <h1>Global International</h1>
          <p>
            Global International work Abroad is the perfect place for clients to
            begin their international career journey. With over 15 years of
            experience in the Global employeement Sector, 3 offices, and
            representing comapnies from around the world, Global International
            is a trailblazer in the field of global career. Headquartered in
            kerala
          </p>
        </div>
      </div>
      <div className="company-info">
        <h2>About Our Company</h2>
        <p>
          Founded in 2024, Work Abroad is dedicated to helping individuals find
          rewarding job opportunities abroad. With a network of employers across
          the globe, we provide the resources and guidance needed for a
          successful international career.
        </p>
        <p>
          Our mission is to bridge the gap between talent and opportunity,
          making the dream of working abroad a reality for professionals
          worldwide.
        </p>
      </div>
    </div>
  );
}

export default CompanyInfo;
