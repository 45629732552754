import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import "../assets/styles/DestinationSelector.css";
import professtional from "../assets/images/professtional.jpeg";
import workaboard from "../assets/images/workaboard.jpeg";
import usa from "../assets/images/usa.jpeg";
import usaMan from "../assets/images/usamanufacture.png";
import usecompany from "../assets/images/usacompany.png";
import travel from "../assets/images/travels.jpeg";
import canadaair from "../assets/images/canadaAir.jpeg";
import canada from "../assets/images/canadatire.png";
import canadaradio from "../assets/images/canadaradio.jpeg";
import person from "../assets/images/person.jpeg";
import work from "../assets/images/work.jpeg";
import interview from "../assets/images/interview.jpeg";
import Australia from "../assets/images/Australia-Post.png";
import Australianab from "../assets/images/austurailanab.jpeg";
import Australian from "../assets/images/Australian.png";
import ukred from "../assets/images/ukred.jpeg";
import ukhouse from "../assets/images/ukhouse.png";
import uktech from "../assets/images/uktech.png";
import portSuper from "../assets/images/portsuper.jpeg";
import portgalp from "../assets/images/portgalp.gif";
import portbel from "../assets/images/portbel.png";
import chinacheery from "../assets/images/chinacherry.jpeg";
import chinacomm from "../assets/images/chinacomm.jpeg";
import chinahuwai from "../assets/images/chinahuwai.jpeg";

const destinations = {
  UnitedStates: {
    backgroundImage: workaboard,
    images: [usa, usaMan, usecompany],
    description:
      "Experience the diverse culture and stunning landscapes of the USA.",
  },
  Canada: {
    backgroundImage: professtional,
    images: [canadaair, canada, canadaradio],
    description: "Discover the natural beauty and vibrant cities of Canada.",
  },
  Australia: {
    backgroundImage: travel,
    images: [Australia, Australianab, Australian],
    description: "Experience the vibrant culture of Australia.",
  },
  UnitedKingdom: {
    backgroundImage: person,
    images: [ukred, ukhouse, uktech],
    description: "Explore the historic cities of the United Kingdom.",
  },
  Portugal: {
    backgroundImage: work,
    images: [portSuper, portgalp, portbel],
    description: "Discover the scenic beauty of Portugal.",
  },
  China: {
    backgroundImage: interview,
    images: [chinacheery, chinacomm, chinahuwai],
    description: "Experience the rich heritage of China.",
  },
};

const YourDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "UnitedStates";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting destinations around the world. Find out
            what makes each location unique and why it should be your next
            travel spot.
          </p>
        </div>
      </div>
      <div className="tabs">
        {Object.keys(destinations).map((country) => (
          <button
            key={country}
            className={`tab-button ${activeTab === country ? "active" : ""}`}
            onClick={() => handleTabClick(country)}
          >
            {country}
          </button>
        ))}
      </div>
      <div className="content" ref={contentRef}>
        <div className="description">
          <h2>{activeTab}</h2>
          <p>{destinations[activeTab].description}</p>
        </div>
        <div className="images">
          {destinations[activeTab].images.map((img, index) => (
            <img
              src={img}
              alt={`${activeTab} ${index}`}
              key={index}
              style={{ height: "150px" }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourDestination;
