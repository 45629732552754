import React from "react";
import "../assets/styles/ContactUs.css";
import contactus from "../assets/images/contactus.jpeg";

const ContactUs = () => {
  return (
    <div className="services-container">
      <div
        className="intross"
        style={{
          backgroundImage: `url(${contactus})`,
          width: "100%",
        }}
      >
        <div className="intross-text">
          <h1>Contact us</h1>
          <p>
            Book a Consultation Call us right now to avail the best counselling
            from our team of experts and grab the opportunity to make your
            dreams come true
          </p>
        </div>
      </div>
      <div className="contact-container">
        <h2 className="contact-title">Get In Touch</h2>
        <p className="contact-description">
          Please fill in all data fields to ensure we can process your enquiry
          as quickly as possible (*required fields).
        </p>
        <form className="contact-form">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <input type="tel" id="mobile" name="mobile" required />
            </div>
            <div className="form-group">
              <label htmlFor="office">Nearest Chakravarthi Office*</label>
              <select id="office" name="office" required>
                <option value="">Select Office</option>
                {/* Add office options here */}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="study-destination">
                Your preferred study destination*
              </label>
              <select id="study-destination" name="study-destination" required>
                <option value="">Select Destination</option>
                {/* Add study destination options here */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="study-plan">When do you plan to study?*</label>
              <select id="study-plan" name="study-plan" required>
                <option value="">Select Time</option>
                {/* Add time options here */}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="counseling-mode">
                Preferred mode of counselling*
              </label>
              <select id="counseling-mode" name="counseling-mode" required>
                <option value="">Select Mode</option>
                {/* Add counseling mode options here */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="study-level">Preferred study level*</label>
              <select id="study-level" name="study-level" required>
                <option value="">Select Level</option>
                {/* Add study level options here */}
              </select>
            </div>
          </div>
          <button type="submit" className="submit-btn">
            Book Now
          </button>
        </form>
      </div>
      <div className="location-container">
        <h2>Our Locations</h2>
        <div className="inner-container">
          <div className="text-container">
            Namakkal (H O) No. 6/1181A, Chakravarthi Towers, Opp to ATC Bus
            Depot, Paramathi Road, Namakkal – 637 001, Tamil Nadu, India. +91
            99420 30120 +91 99421 30120 Locate Us
          </div>
          <div className="text-container">
            Chennai Workafella, No. 504, 5th Floor, Anna Salai, Teynampet,
            Chennai – 600 018, Tamil Nadu, India. +91 90870 30120 +91 89033
            30120 Locate Us
          </div>
          <div className="text-container">
            Thanjavur No. 24, 1st Floor, SR Complex, Opp to New Bus Stand,
            Trichy Road, Thanjavur – 613 005, Tamil Nadu, India. +91 86088 30120
            +91 86089 30120 Locate Us
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
