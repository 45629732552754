import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/Navbar.css";
import gobalinter from "../assets/images/global international.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src={gobalinter} style={{ height: "55px", width: "55px" }} />
        <Link to="/">Global International</Link>
      </div>
      <div
        className={`navbar-toggle ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div className={`navbar-links ${isOpen ? "active" : ""}`}>
        <Link to="/">Home</Link>
        <Link to="/destination">Your Destination</Link>
        <Link to="/service">Services</Link>
        <Link to="/about">About Us</Link>
        <Link to="/Contact">Contact Us</Link>
      </div>
    </nav>
  );
}

export default Navbar;
