import React from "react";
import ImageCarousel from "../components/Carousel";
import CounselingSection from "../components/CounselingSection";
import DestinationSelector from "../components/DestinationSelector";
import "../assets/styles/Home.css";
import Testimonials from "../components/Testimonials";
import Services from "../components/Services";
import Footer from "../components/Footer";
import AboveFooter from "../components/AboveFooter";

function Home() {
  return (
    <div className="home">
      <div className="carousel-wrapper">
        <ImageCarousel />
        <CounselingSection />
      </div>
      <section className="intro">
        <h2>Explore Opportunities Abroad</h2>
        <p>
          Find the best jobs in your desired country and take the first step
          towards your global career.
        </p>
      </section>
      <DestinationSelector />
      <Services />
      <Testimonials />
      <AboveFooter />
      <Footer />
    </div>
  );
}

export default Home;
