import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/styles/Testimonials.css";

const testimonials = [
  {
    name: "John Doe",
    feedback:
      "This service was amazing! It really helped me prepare for my career abroad.",
    location: "New York, USA",
  },
  {
    name: "Jane Smith",
    feedback:
      "I received excellent guidance and support throughout the visa process.",
    location: "Toronto, Canada",
  },
  {
    name: "Carlos Ruiz",
    feedback: "Highly recommended for anyone looking to study or work abroad.",
    location: "Madrid, Spain",
  },
  {
    name: "John Doe",
    feedback:
      "This service was amazing! It really helped me prepare for my career abroad.",
    location: "New York, USA",
  },
  {
    name: "Jane Smith",
    feedback:
      "I received excellent guidance and support throughout the visa process.",
    location: "Toronto, Canada",
  },
  {
    name: "Carlos Ruiz",
    feedback: "Highly recommended for anyone looking to study or work abroad.",
    location: "Madrid, Spain",
  },
];

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Display three testimonials at a time
    slidesToScroll: 1, // Scroll one testimonial at a time
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // For small screens
        settings: {
          slidesToShow: 1, // Show 1 testimonial on small screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // For medium screens
        settings: {
          slidesToShow: 2, // Show 2 testimonials on medium screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonials">
      <h2>What Our Clients Say</h2>
      <Slider {...settings} className="testimonial-slider">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <p className="testimonial-feedback">"{testimonial.feedback}"</p>
            <p className="testimonial-name">- {testimonial.name}</p>
            <p className="testimonial-location">{testimonial.location}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
