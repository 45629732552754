import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/DestinationSelector.css";
import America from "../assets/images/amaerica.jpg";
import Canada from "../assets/images/canada.png";
import Australia from "../assets/images/Australia.jpg";
import UK from "../assets/images/Uk.jpeg";
import Portugal from "../assets/images/Portugal.jpeg";
import China from "../assets/images/china.jpeg";

const destinations = [
  {
    name: "UnitedStates",
    imageUrl: America,
    path: "/destination/UnitedStates",
  },
  { name: "Canada", imageUrl: Canada, path: "/destination/canada" },
  { name: "Australia", imageUrl: Australia, path: "/destination/australia" },
  { name: "UnitedKingdom", imageUrl: UK, path: "/destination/UnitedKingdom" },
  { name: "Portugal", imageUrl: Portugal, path: "/destination/portugal" },
  { name: "China", imageUrl: China, path: "/destination/china" },
];

const DestinationSelector = () => {
  const history = useHistory();
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    setAnimationClass("animate");
  }, []);

  const handleDestinationClick = (path, name) => {
    history.push({
      pathname: path,
      state: { selectedTab: name }, // Pass the selected destination name
    });
  };

  return (
    <div className="destination-selector">
      <h2>Choose Your Destination</h2>
      <div className="destination-cards">
        {destinations.map((destination, index) => (
          <div
            key={index}
            className={`destination-card ${
              index % 2 === 0
                ? `${animationClass}-right`
                : `${animationClass}-left`
            }`}
            onClick={() =>
              handleDestinationClick(destination.path, destination.name)
            }
          >
            <img src={destination.imageUrl} alt={destination.name} />
            <div className="destination-info">
              <h3>{destination.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DestinationSelector;
