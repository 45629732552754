import React, { useEffect, useRef } from "react";
import "../assets/styles/Services.css";

const services = [
  { title: "Career Counseling", icon: "🎓" },
  { title: "Financial Aid", icon: "💰" },
  { title: "Visa Preparation", icon: "🛂" },
  { title: "Pre-Departure Guidance", icon: "✈️" },
  { title: "Forex Services", icon: "💱" },
  { title: "Transport & Accommodation", icon: "🏨" },
  { title: "University Application", icon: "📄" },
  { title: "Language Classes", icon: "🗣️" },
  { title: "Job Placement Assistance", icon: "💼" },
];

const Services = () => {
  const servicesRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-service");
        }
      });
    }, observerOptions);

    servicesRef.current.forEach((service) => {
      if (service) {
        observer.observe(service);
      }
    });

    return () => {
      if (servicesRef.current) {
        servicesRef.current.forEach((service) => {
          if (service) {
            observer.unobserve(service);
          }
        });
      }
    };
  }, []);

  return (
    <div className="services">
      <h2>Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-card"
            ref={(el) => (servicesRef.current[index] = el)}
          >
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
